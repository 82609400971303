.theme {
  --theme-color: #1d4ed8;
}

.light {
  --bg-primary-light: #e8edfb;
  --bg-nuetral-light: #f8fafc;
  --text-primary-light: #173ead;
  --text-secondary-light: #0f172a;
  --text-link: #1d4ed8;
}
/* 081d26 */
.dark {
  --theme-bg-dark: #081d26;
  --theme-bg-muted-dark: #2d3741;
  --theme-bg-accent-dark: #2d3741;
  --text-primary-dark: #ffffff;
  --text-secondary-dark: #d3d3d3;
  --text-link: #1d9bf0;
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.bg-primary {
  background-color: var(--bg-primary);
}

.metric-scroll::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.metric-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #18242e;
  border-radius: 3px;
}

.metric-scroll::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 2px grey;
  border-radius: 8px;
}

/* Handle */
.metric-scroll::-webkit-scrollbar-thumb {
  background: #18242e;
  border-radius: 3px;
}

/* Handle on hover */
.metric-scroll::-webkit-scrollbar-thumb:hover {
  background: #18242e;
  width: 8px;
  cursor: pointer;
}
/* light scroll */
.metric-scroll-light::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.metric-scroll-light::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #0e1011;
  border-radius: 3px;
}

.metric-scroll-light::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 2px grey;
  border-radius: 3px;
}

/* Handle */
.metric-scroll-light::-webkit-scrollbar-thumb {
  background: #1c2834;
  border-radius: 3px;
}

/* Handle on hover */
.metric-scroll-light::-webkit-scrollbar-thumb:hover {
  background: #067ad8;
  width: 8px;
  cursor: pointer;
}

/* dark scroll */
.metric-scroll-dark::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.metric-scroll-dark::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #fff;
  border-radius: 3px;
}

.metric-scroll-dark::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 2px #fff;
  border-radius: 8px;
}

/* Handle */
.metric-scroll-dark::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 3px;
}

/* Handle on hover */
.metric-scroll-dark::-webkit-scrollbar-thumb:hover {
  background: #fff;
  width: 8px;
  cursor: pointer;
}

input::placeholder {
  font-size: 12px;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.broker-tag {
  background: #d0d3d8;
  color: rgb(59, 58, 58);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 10px;
  margin: 3px 2px;
  border-radius: 6px;
  display: inline-block;
}

.filter-leaks .selection input[type='radio'] {
  border: 2px solid #1c65ff;
  padding: 0.4em;
  -webkit-appearance: none;
  border-radius: 2px;
}

.filter-leaks .selection input[type='radio']:checked {
  background: #1c65ff;
  background-size: 9px 9px;
}

.mini-chart {
  /* margin: 0px auto; */
  width: 280px;
  border-radius: 4px;
  overflow: auto;
}

svg {
  height: 100%;
  width: 100%;
}

.description {
  margin: auto;
  max-width: 320px;
}

.dropdown:hover > .dropdown-content {
  display: block;
}

.chartdiv {
  width: 100% !important;
  height: 99vh !important;
  max-height: 700px !important;
}

.td-th {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px 20px;
}
.--quick-links-hover-bg {
  background-color: 'red';
}

.pilltab {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.pilltab::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}