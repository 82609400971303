@tailwind base;
@tailwind components;
@tailwind utilities;

:is(.dark) {
  --quick-links-hover-bg: #1e293b;
}
body {
  margin: 0;
  font-family: 'poppins', sans-serif;
  /* font-family: 'Nunito', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hp-gradient_blue-green_v {
  background-color: rgb(52, 168, 83);
  background-image: -moz-linear-gradient(
    0deg,
    rgba(52, 168, 83, 1) 0%,
    rgba(66, 133, 244, 1) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(52, 168, 83, 1) 0%,
    rgba(66, 133, 244, 1) 100%
  );
  background-image: linear-gradient(
    0deg,
    rgba(52, 168, 83, 1) 0%,
    rgba(66, 133, 244, 1) 100%
  );
}
.hp-gradient_text {
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
/* to remove arrow from input type=[number] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.index_beams {
  background-image: url(./Assets/Images/gradient-beam.png) !important;
  background-size: 150rem;
}
.overview-card {
  background: linear-gradient(
        var(--quick-links-hover-bg, #f0f9ff),
        var(--quick-links-hover-bg, #f0f9ff)
      )
      padding-box,
    linear-gradient(to top, #818cf8, #22d3ee, #0ea5e9) border-box;
}
